<template>
  <div>
    <div class="container" align="center">
      <div class="flex justify-content-center w-100">
        <div id="btn-radios-3" class="categoryRadioGroup">
          <label class="cateBoxContainer">
            <input type="radio" value="핀크스 골프클럽" v-model="tab_name" />
            <div class="cateBox">
              <div class="innerText">핀크스 골프클럽</div>
            </div>
          </label>
          <label class="cateBoxContainer">
            <input type="radio" value="포도호텔" v-model="tab_name" />
            <div class="cateBox">
              <div class="innerText">포도호텔</div>
            </div>
          </label>
          <label class="cateBoxContainer">
            <input type="radio" value="디아넥스" v-model="tab_name" />
            <div class="cateBox">
              <div class="innerText">디아넥스</div>
            </div>
          </label>
        </div>
      </div>

      <TOSgolfclub v-if="tab_name === '핀크스 골프클럽'" />
      <TOSpodohotel v-if="tab_name === '포도호텔'" />
      <TOSannex v-if="tab_name === '디아넥스'" />
    </div>
  </div>
</template>

<script>
import TOSgolfclub from '@/pages/Footerlink/Components/TOSgolfclub';
import TOSpodohotel from '@/pages/Footerlink/Components/TOSpodohotel';
import TOSannex from '@/pages/Footerlink/Components/TOSannex';

export default {
  name: 'TOSpinx',
  data() {
    return {
      tab_name: '핀크스 골프클럽',
    };
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '이용약관'});
  },
  components: {TOSgolfclub, TOSpodohotel, TOSannex},
};
</script>

<style scoped lang="scss">
.categoryRadioGroup {
  width: 100%;
  height: 3rem;
}

.cateBoxContainer {
  width: 33%;
  height: 100%;
  border: 1px solid #b7b7b7;
  margin: 0rem;
}

input[type='radio'] {
  display: none;
  & {
    + .cateBox {
      display: table;
      width: 100%;
      height: 100%;
      background-color: white;
      font-weight: bold;
      padding: 0.2rem;
      cursor: pointer;
      .innerText {
        display: table-cell;
        vertical-align: middle;
        margin-top: auto;
        margin-bottom: auto;
        align-self: center;
        word-break: keep-all;
      }
    }
  }
  &:checked {
    + .cateBox {
      background-color: #524a3d;
      color: white;
    }
  }
}
@media (min-width: 992px) {
  .innerText {
    font-size: 1rem;
  }
}
@media (max-width: 992px) {
  .innerText {
    font-size: 0.8rem;
  }
}
</style>
